import { RosterApparatus, RosterPosition, RosterEmployee, DEFAULT_RANK } from '@stationwise/share-types';
import { getVacancies } from '../board';
import { checkIsShift } from '../id';
import { IShiftSummaryHelper, RankRequirementCount } from '../types';

export const getApparatusRanks = (apparatus: RosterApparatus) => {
  const rankMap = new Map<string, RankRequirementCount>();
  apparatus.positions.forEach((position) => {
    if (!rankMap.get(position.rank.code) && !position.isTemporary) {
      rankMap.set(position.rank.code, { total: 0, amount: 0, obj: position.rank });
    }
  });
  apparatus.positions.forEach((position) => {
    const count = rankMap.get(position.rank.code);
    if (count) {
      count.total += !position.isTemporary ? 1 : 0;
      count.amount += !getVacancies(position).length ? 1 : 0;
    }
  });
  return rankMap;
};

export const checkIsActingAsRank = (
  shiftSummaryHelper: IShiftSummaryHelper,
  position: RosterPosition,
  employee: RosterEmployee,
) => {
  if (position.rank == DEFAULT_RANK) {
    return false;
  }
  const plannedAssignments = shiftSummaryHelper.plannedAssignments.get(employee.id) || [];
  const isPlanned = plannedAssignments.some((pa) => `${pa.positionId}` === position.id);
  return !isPlanned && position.rank.code !== employee.rank.code;
};

export const getActingAsRankCounts = (shiftSummaryHelper: IShiftSummaryHelper) => {
  let totalCount = 0;
  const rankCounts = new Map<string, number>();
  const countedEmployeeIds = new Set<string>();
  for (const station of shiftSummaryHelper.allStationCards.values()) {
    if (checkIsShift(station)) {
      for (const apparatus of station.apparatuses) {
        for (const position of apparatus.positions) {
          for (const employee of position.employees) {
            if (!countedEmployeeIds.has(employee.id) && checkIsActingAsRank(shiftSummaryHelper, position, employee)) {
              totalCount += 1;

              const rankCount = rankCounts.get(position.rank.code) ?? 0;
              rankCounts.set(position.rank.code, rankCount + 1);

              countedEmployeeIds.add(employee.id);
            }
          }
        }
      }
    }
  }

  return { totalCount, rankCounts };
};

import { RosterPosition, RosterEmployee, RosterStrikeTeamApparatus } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { copyBoardWithNewApparatus } from '../board';
import { checkIsStrikeTeamApparatus } from '../id';
import { keepPlannedEmployeeOnRoster } from '../keepPlannedEmployeeOnRoster';
import { IShiftSummaryHelper } from '../types';

export const unassignStrikeTeamEmployee = (
  shiftSummaryHelper: IShiftSummaryHelper,
  apparatus: RosterStrikeTeamApparatus,
  position: RosterPosition,
  assignee: RosterEmployee,
  toDate: string,
) => {
  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(shiftSummaryHelper, apparatus.id);
  const newPosition = newApparatus.positions.find((p) => p.id === position.id);
  if (!checkIsStrikeTeamApparatus(newApparatus) || !newPosition) {
    throw new Error('Cannot unassign strike team employee');
  }

  newApparatus.strikeTeamAssignmentPayloadMap = new Map(newApparatus.strikeTeamAssignmentPayloadMap);
  newApparatus.strikeTeamAssignmentPayloadMap.set(
    position.id,
    (newApparatus.strikeTeamAssignmentPayloadMap.get(position.id) || []).filter((payload) => {
      const startTime = differenceInUTCMinutes(assignee.startDateTime, shiftSummaryHelper.shiftDuration.startTime);
      const endTime = differenceInUTCMinutes(assignee.endDateTime, shiftSummaryHelper.shiftDuration.startTime);
      return payload.startTime !== startTime || payload.endTime !== endTime;
    }),
  );

  newApparatus.strikeTeamUnassignmentPayloadMap = new Map(newApparatus.strikeTeamUnassignmentPayloadMap);
  newApparatus.strikeTeamUnassignmentPayloadMap.set(position.id, [
    ...(newApparatus.strikeTeamUnassignmentPayloadMap.get(position.id) || []),
    {
      positionId: position.isTemporary ? null : position.id,
      assigneeId: assignee.id,
      startTime: differenceInUTCMinutes(assignee.startDateTime, shiftSummaryHelper.shiftDuration.startTime),
      endTime: differenceInUTCMinutes(assignee.endDateTime, shiftSummaryHelper.shiftDuration.startTime),
      strikeTeamId: apparatus.strikeTeam.id,
      toDate,
    },
  ]);

  if (!newPosition.isTemporary) {
    newPosition.employees = newPosition.employees.filter((e) => e.activeId !== assignee.activeId);
  } else {
    newApparatus.positions = newApparatus.positions.filter((p) => p.id !== position.id);
  }

  let newShiftSummaryHelper = { ...shiftSummaryHelper, allStationCards: newAllStationCards };
  newShiftSummaryHelper = keepPlannedEmployeeOnRoster(newShiftSummaryHelper, assignee);
  return { newShiftSummaryHelper };
};

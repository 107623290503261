import { Box, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import {
  Badge,
  Chip,
  CircleHigherIcon20,
  OvalChip,
  useLoadedDepartmentInfoContext,
  useRosterContext,
} from '@stationwise/component-module';
import { RosterApparatus, RosterPosition, RosterEmployee, EmployeeOffView } from '@stationwise/share-types';
import {
  checkHasMandatoryOvertimeDetailCode,
  checkHasVoluntaryOvertimeDetailCode,
  checkIsActingAsRank,
  checkIsAdministration,
  checkIsPlannedEmployee,
  formatShiftTime,
  getEmployeeOffDuration,
  getPositionSplits,
} from '@stationwise/shift-summary-helper';
import { Cell } from './Cell';
import { Row } from './Row';

interface Props {
  apparatus: RosterApparatus;
  position: RosterPosition;
  employeesOff: EmployeeOffView[];
}

export const PositionRows = ({ apparatus, position, employeesOff }: Props) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { shiftSummaryHelper } = useRosterContext();
  const theme = useTheme();
  const splits = getPositionSplits(position);

  const getRowClasses = (splitItem: RosterPosition | RosterEmployee, splitIndex: number) => {
    const classes: string[] = [];
    if (splitItem === splits[splitIndex].vacancy) {
      classes.push('SWPrintDaySchedule-circled-row', 'SWPrintDaySchedule-dashed-row');
    } else if (apparatus.isForShiftLeader && splits.length === 1) {
      classes.push('SWPrintDaySchedule-circled-row');
    } else {
      classes.push('SWPrintDaySchedule-lined-row');
      if (splits.length > 1) {
        classes.push('SWPrintDaySchedule-dashed-row');
      }
    }
    return classes.join(' ');
  };

  const renderEmployeeCertifications = (employee: RosterEmployee) => {
    return (
      <Box sx={{ display: 'flex', gap: 1.5 }}>
        {employee.certifications.map((cert, index) => (
          <Box key={`${index}-${cert.code}`} sx={{ textWrap: 'nowrap', typography: 'bodyXSRegular' }}>
            {cert.code}
          </Box>
        ))}
      </Box>
    );
  };

  const renderPositionRank = () => {
    return (
      <Box sx={{ display: 'inline-flex' }}>
        <Badge
          label={position.rank.name}
          isRound
          type="sm"
          color={position.rank.color}
          isBold
          noPadding
          isCustom
          customBackgroundColor={'white'}
          customColor={'black'}
          overrideMaxLength={checkIsAdministration(position) ? 16 : undefined}
        />
      </Box>
    );
  };

  const renderEmployeeName = (employee: RosterEmployee) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ fontSize: '14px', fontWeight: 600, mt: '3px' }}>{employee.name}</Box>
        {checkIsActingAsRank(shiftSummaryHelper, position, employee) && (
          <Box sx={{ display: 'inline-flex', position: 'relative', left: theme.spacing(1.5) }}>
            <Chip label="A" />
          </Box>
        )}
      </Box>
    );
  };

  const renderTimeLabel = (splitItem: RosterPosition | RosterEmployee) => {
    const isFirstSplit = splitItem === splits[0].vacancy || splitItem === splits[0].employee;
    const isLastSplit = splitItem === splits[splits.length - 1].vacancy || splitItem === splits[splits.length - 1].employee;
    return (
      <Box sx={{ mt: '3px' }}>
        <Box component="span" sx={{ color: splits.length === 1 || isFirstSplit ? undefined : theme.palette.stationGray[400] }}>
          {formatShiftTime(splitItem.startDateTime)}
        </Box>
        {' - '}
        <Box component="span" sx={{ color: splits.length === 1 || isLastSplit ? undefined : theme.palette.stationGray[400] }}>
          {formatShiftTime(splitItem.endDateTime)}
        </Box>
      </Box>
    );
  };

  const renderOffRoster = (splitItem: RosterPosition | RosterEmployee) => {
    const findOverlappingEmployeeOffData = () => {
      for (const employeeOffData of employeesOff) {
        const offDuration = getEmployeeOffDuration(shiftSummaryHelper, employeeOffData);
        if (splitItem.startDateTime < offDuration.endDateTime && splitItem.endDateTime > offDuration.startDateTime) {
          return employeeOffData;
        }
      }
      return null;
    };

    const employeeOffData = findOverlappingEmployeeOffData();
    if (!employeeOffData) {
      return null;
    }

    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ typography: 'bodyS', mt: '3px' }}>
          {`${employeeOffData.employeeOff.firstName} ${employeeOffData.employeeOff.lastName}`}
          <br />
          {`(${employeeOffData.title})`}
        </Box>
      </Box>
    );
  };

  const renderEmployeeShiftType = (employee: RosterEmployee) => {
    let indicator: ReactNode = null;
    if (checkHasMandatoryOvertimeDetailCode(employee)) {
      const { mandatoryOvertimeNaming } = departmentInfoState.departmentInfo;
      indicator = <OvalChip label={mandatoryOvertimeNaming.abbr} backgroundColor={'white'} color={'black'} />;
    } else if (checkHasVoluntaryOvertimeDetailCode(employee)) {
      indicator = <OvalChip label="VOL" backgroundColor={'white'} color={'black'} />;
    } else if (employee.trade) {
      indicator = (
        <Box sx={{ display: 'inline-flex', flexWrap: 'wrap', gap: theme.spacing(0.25, 1.5) }}>
          <OvalChip label="TRADE" backgroundColor={'white'} color={'black'} />
        </Box>
      );
    }
    return !indicator ? null : <Box sx={{ display: 'inline-flex', mt: '2px' }}>{indicator}</Box>;
  };

  const renderEmployeeNotes = (employee: RosterEmployee) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {position.isTemporary && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, '& svg': { width: '20px', height: '20px' } }}>
            <CircleHigherIcon20 />
            Excess capacity
          </Box>
        )}
        {employee.team && !checkIsPlannedEmployee(shiftSummaryHelper, employee) && !checkIsAdministration(employee) && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mt: '3px' }}>{employee.team.name}</Box>
        )}
      </Box>
    );
  };

  const renderVacantRow = (vacancy: RosterPosition, splitIndex: number) => {
    return (
      <Row
        key={`vacant:${position.id}:${vacancy.startDateTime.getTime()}:${vacancy.endDateTime.getTime()}`}
        className={getRowClasses(vacancy, splitIndex)}
        sx={{ background: 'white', borderColor: 'black', color: 'black' }}
      >
        <Cell colIndex={0}>
          <Box sx={{ fontSize: '14px', fontWeight: 600, mt: '3px' }}>Vacant</Box>
        </Cell>
        <Cell colIndex={1}>{renderPositionRank()}</Cell>
        <Cell colIndex={2}>{renderTimeLabel(vacancy)}</Cell>
        <Cell colIndex={3}>{renderOffRoster(vacancy)}</Cell>
        <Cell colIndex={4} />
        <Cell colIndex={5} />
        <Cell colIndex={6} />
      </Row>
    );
  };

  const renderAssignedRow = (employee: RosterEmployee, splitIndex: number) => {
    return (
      <Row key={`assigned:${position.id}:${employee.activeId}`} className={getRowClasses(employee, splitIndex)}>
        <Cell colIndex={0}>{renderEmployeeName(employee)}</Cell>
        <Cell colIndex={1}>{renderPositionRank()}</Cell>
        <Cell colIndex={2}>{renderTimeLabel(employee)}</Cell>
        <Cell colIndex={3}>{renderOffRoster(employee)}</Cell>
        <Cell colIndex={4}>{renderEmployeeCertifications(employee)}</Cell>
        <Cell colIndex={5}>{renderEmployeeShiftType(employee)}</Cell>
        <Cell colIndex={6}>{renderEmployeeNotes(employee)}</Cell>
      </Row>
    );
  };

  return splits.map(({ vacancy, employee }, splitIndex) => {
    return employee ? renderAssignedRow(employee, splitIndex) : renderVacantRow(vacancy, splitIndex);
  });
};

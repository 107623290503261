import { format, max, min } from 'date-fns';
import { RosterStrikeTeamApparatus, RosterPosition } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { copyBoardWithNewApparatus } from '../board';
import { setEmployeeActiveId } from '../id';
import { keepPlannedEmployeeOnRoster } from '../keepPlannedEmployeeOnRoster';
import { IShiftSummaryHelper } from '../types';
import { makeStrikeTeamApparatuses } from './station';

export const undeployStrikeTeamApparatus = (
  shiftSummaryHelper: IShiftSummaryHelper,
  apparatus: RosterStrikeTeamApparatus,
  deactivationDateTime: Date,
) => {
  let newShiftSummaryHelper = { ...shiftSummaryHelper };
  const newPositions: RosterPosition[] = [];
  apparatus.positions.forEach((p, i) => {
    const newPosition: RosterPosition = {
      ...p,
      id: `${-(Date.now() + i)}`,
      employees: [],
      endDateTime: min([p.endDateTime, deactivationDateTime]),
    };
    if (p.startDateTime < deactivationDateTime) {
      newPositions.push(newPosition);
    }

    p.employees.forEach((e) => {
      if (e.startDateTime < deactivationDateTime) {
        const newEmployee = setEmployeeActiveId({ ...e, endDateTime: min([e.endDateTime, deactivationDateTime]) });
        newPosition.employees.push(newEmployee);
      }

      if (e.endDateTime > deactivationDateTime) {
        const newEmployee = setEmployeeActiveId({ ...e, startDateTime: max([e.startDateTime, deactivationDateTime]) });
        newShiftSummaryHelper = keepPlannedEmployeeOnRoster(newShiftSummaryHelper, newEmployee);
      }
    });
  });

  const { newAllStationCards, newStation, apparatusIndex } = copyBoardWithNewApparatus(newShiftSummaryHelper, apparatus.id);

  if (!newPositions.length) {
    newStation.apparatuses.splice(apparatusIndex, 1);
    return { ...newShiftSummaryHelper, allStationCards: newAllStationCards };
  }

  const newStrikeTeamPositions = newPositions
    .filter((p) => !p.isTemporary)
    .map((p) => ({
      id: Number(p.id),
      rank: p.rank,
      certifications: p.certifications,
      startDateTime: format(p.startDateTime, "yyyy-MM-dd'T'HH:mm:'00'"),
      endDateTime: format(p.endDateTime, "yyyy-MM-dd'T'HH:mm:'00'"),
      employees: [],
    }));

  const newStrikeTeam = {
    ...apparatus.strikeTeam,
    id: -Date.now(),
    activationDate: format(shiftSummaryHelper.shiftDuration.startTime, 'yyyy-MM-dd'),
    deactivationDate: format(shiftSummaryHelper.shiftDuration.startTime, 'yyyy-MM-dd'),
    positions: newStrikeTeamPositions,
    temporaryPositions: [],
  };

  const [newApparatus] = makeStrikeTeamApparatuses([newStrikeTeam]);
  newStation.apparatuses[apparatusIndex] = newApparatus;
  newApparatus.positions = newPositions;
  newApparatus.positions.forEach((p) => {
    p.employees.forEach((e) => {
      newApparatus.strikeTeamAssignmentPayloadMap.set(p.id, [
        ...(newApparatus.strikeTeamAssignmentPayloadMap.get(p.id) || []),
        {
          positionId: p.isTemporary ? null : p.id,
          candidateId: Number(e.id),
          strikeTeamId: newStrikeTeam.id,
          startTime: differenceInUTCMinutes(e.startDateTime, newShiftSummaryHelper.shiftDuration.startTime),
          endTime: differenceInUTCMinutes(e.endDateTime, newShiftSummaryHelper.shiftDuration.startTime),
          toDate: newStrikeTeam.deactivationDate,
          payCodeIds: e.payCodes.map((pc) => pc.id),
          dutyDayPayCodeIds: null,
          detailCodeIds: e.detailCodes.map((dc) => dc.id),
        },
      ]);
    });
  });

  return { ...newShiftSummaryHelper, allStationCards: newAllStationCards };
};

import { useDraggable } from '@dnd-kit/core';
import { Box, BoxProps, useMediaQuery } from '@mui/material';
import { useRosterContext } from '../RosterContext';

interface DraggableCardProps extends BoxProps<'div'> {
  activeId: string;
  disabled?: boolean;
}

export const DraggableCard = ({ activeId, children, sx, disabled: disabledProp, ...props }: DraggableCardProps) => {
  const { isReadonly } = useRosterContext();
  const isMobileDevice = useMediaQuery('(pointer: fine) and (hover: none)');
  const disabled = isReadonly || disabledProp || isMobileDevice || false;
  const { attributes, isDragging, listeners, setNodeRef } = useDraggable({ id: activeId, disabled });
  return (
    <Box
      data-cy={`draggable-card-${activeId}`}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      {...props}
      sx={[
        (theme) => ({
          background: theme.palette.common.white,
          borderRadius: '12px',
          position: 'relative',
          height: '100%',
          width: '100%',
          cursor: !disabled ? 'grab' : 'pointer',
          zIndex: theme.zIndex.mobileStepper,
          '.SWDragOverlay-active &': {
            opacity: isDragging ? 0 : undefined,
          },
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {children}
    </Box>
  );
};

import { Box } from '@mui/material';
import { Button } from '@stationwise/component-module';
import { GetIncidentOverview } from '@stationwise/share-types';
// import { useIOSPaddingBottom } from '../../../../../../../../apps/ui/src/context/DeviceInfoContext';
import { IncidentFormDrawer } from './IncidentFormDrawer';

interface CreateIncidentProps {
  handleIncidentRequest: (responseMessage: string, error: boolean) => void;
  incidentFormOpen: boolean;
  onClose: () => void;
  open: boolean;
  selectedDate: string;
  selectedIncident?: GetIncidentOverview;
  setIncidentFormOpen: (value: boolean) => void;
  setSelectedIncident: React.Dispatch<React.SetStateAction<GetIncidentOverview | undefined>>;
  isCloseIncident: boolean;
  setIsCloseIncident: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateIncident = ({
  handleIncidentRequest,
  incidentFormOpen,
  onClose,
  open,
  selectedDate,
  selectedIncident,
  setIncidentFormOpen,
  setSelectedIncident,
  isCloseIncident,
  setIsCloseIncident,
}: CreateIncidentProps) => {
  // const iosWebViewPaddingBottom = useIOSPaddingBottom();
  const iosWebViewPaddingBottom = 0;

  const handleCreateIncidentClick = () => {
    setSelectedIncident(undefined);
    setIncidentFormOpen(true);
  };
  const handleCloseIncidentClick = () => {
    setIncidentFormOpen(false);
  };

  if (incidentFormOpen) {
    return (
      <IncidentFormDrawer
        handleIncidentRequest={(responseMessage: string, error: boolean) => {
          handleIncidentRequest(responseMessage, error);
          setIncidentFormOpen(false);
        }}
        onClose={handleCloseIncidentClick}
        open={incidentFormOpen}
        selectedDate={selectedDate}
        selectedIncident={selectedIncident}
        isCloseIncident={isCloseIncident}
        setIsCloseIncident={setIsCloseIncident}
        setSelectedIncident={setSelectedIncident}
        handleOnClose={handleCloseIncidentClick}
      />
    );
  }

  if (open) {
    // The `spacer` ensures the `content` doesn't overlap with whatever is rendered above this component.
    // The height of the `spacer` is roughly the height of the `content` when `See all` is in the collapsed state.
    const spacer = <Box sx={{ height: '148px' }} />;
    const content = (
      <Box
        sx={(theme) => ({
          position: 'fixed',
          bottom: iosWebViewPaddingBottom,
          width: 'calc(100% - 16px)',
          margin: theme.spacing(1),
          border: '1px solid #DEE3ED',
          borderRadius: '16px',
          boxShadow: '0px -10px 15px -3px rgba(10, 14, 22, 0.1)',
          background: theme.palette.common.white,
          zIndex: theme.zIndex.modal,
        })}
      >
        <Box
          sx={(theme) => ({
            p: theme.spacing(2),
            typography: 'bodyMMedium',
          })}
        >
          Select a day you want to create incident.
        </Box>

        <Box
          sx={(theme) => ({
            width: '100%',
            p: theme.spacing(1),
            justifyContent: 'center',
            display: 'flex',
          })}
        >
          <Box
            sx={(theme) => ({
              width: '50%',
              m: theme.spacing(1),
            })}
          >
            <Button
              data-cy="request-overtime-cancel-button"
              disableFocusRipple={true}
              disableRipple={true}
              variant="contained"
              buttonType="tertiary"
              onClick={() => onClose()}
              sx={(theme) => ({
                background: theme.palette.common.white,
                color: theme.palette.common.black,
                width: '100%',
                borderWidth: theme.spacing(0.1),
                textTransform: 'none',
                borderRadius: theme.spacing(0.75),
              })}
            >
              Cancel
            </Button>
          </Box>
          <Box sx={(theme) => ({ width: '50%', m: theme.spacing(1) })}>
            <Button
              variant="contained"
              disabled={selectedDate ? false : true}
              disableFocusRipple={true}
              disableRipple={true}
              onClick={handleCreateIncidentClick}
              sx={(theme) => ({
                background: theme.palette.common.black,
                width: '100%',
                textTransform: 'none',
                borderRadius: theme.spacing(0.75),
              })}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
    );
    return (
      <>
        {spacer}
        {content}
      </>
    );
  }

  return null;
};

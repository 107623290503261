import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { format, isAfter, startOfDay } from 'date-fns';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  Drawer,
  DRAWER_SIDE,
  DrawerHeader,
  HireBackListHeader,
  HireBackList,
  StaffingListHeader,
  RosterContextProvider,
  useRoster,
  SnackbarService,
  useLoadedAuthUserContext,
  useLoadedDepartmentInfoContext,
  Modal,
  useAuthUserCapabilities,
} from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import {
  ShiftSummary,
  ListFieldsStaffingList,
  BulkCallDetailsView,
  TemporaryNonShiftType,
  TEMPORARY_NON_SHIFT_TITLES,
  RosterStation,
  RosterApparatus,
  RosterPosition,
  RosterAdministrationStation,
  RosterEmployee,
  EmployeeOffView,
  RosterTemporaryNonShiftStation,
  AutoHireInfoView,
} from '@stationwise/share-types';
import {
  differenceInUTCMinutes,
  PUSHER_EVENT_TYPES,
  PUSHER_UPDATE_MESSAGE,
  RefreshEventCallback,
} from '@stationwise/share-utils';
import {
  checkHasMandatoryOvertimeDetailCode,
  checkHasVoluntaryOvertimeDetailCode,
  checkIsShift,
  checkIsStrikeTeam,
  getBoardEmployees,
  getVacancies,
} from '@stationwise/shift-summary-helper';
import { CreateButton } from '../../../../components/Common/CreateButton';
import { AutoHireVacancy, getVacantStations } from '../../HiringEngine/Vacancies/vacanciesHelper';
import { DRAWER_TYPE, DRAWER_WIDTH } from '../constants';
import { DaySchedule } from './DaySchedule';
import { EmployeesOff } from './EmployeesOff';
import { ForceShiftTradeModal } from './ForceShiftTrade/ForceShiftTradeModal';
import { MessageToWorkingEmployees } from './Messaging/MessageToWorkingEmployees';
import { MiniCalendar } from './MiniCalendar/MiniCalendar';
import { MoveToTemporaryNonShiftModal } from './MoveToTemporaryNonShiftModal';
import { PrintDaySchedulePrint } from './PrintDaySchedule/PrintDaySchedulePrint';
import { RosterChangeLogDrawer } from './RosterChangelog';
import { StaffingStatsDrawer } from './StaffingStats';
import { StrikeTeam } from './StrikeTeam';
import { EditStrikeTeamModal } from './StrikeTeam/EditStrikeTeamModal';
import { TopBar } from './TopBar';
import { UnplannedAssignmentWarning } from './UnplannedAssignmentWarning';
interface ShiftSummaryAssignmentPayload {
  employeeId: string | null;
  apparatusId: string | null;
  positionId: string | null;
  startTime: number;
  endTime: number;
  payCodes: number[];
  detailCodes: number[];
  isMandatory: boolean;
  isVoluntary: boolean;
  staffedAt: Date | null;
}

interface Props {
  selectedBattalionState: [number | undefined, Dispatch<SetStateAction<number | undefined>>];
  shiftSummary: ShiftSummary;
  administrationStations: RosterAdministrationStation[];
  eventStations: RosterStation[];
  temporaryNonShiftStation: RosterTemporaryNonShiftStation;
  currentDate: Date;
  forceRefetch: (battalionId?: number) => void;
  staffingListsResponse: { data: ListFieldsStaffingList[] | null; isError: boolean };
  bulkCalls: BulkCallDetailsView[];
  employeesOff: EmployeeOffView[];
  autoHireInfo: AutoHireInfoView | null;
}

export const ScheduleContent = ({
  selectedBattalionState,
  shiftSummary,
  administrationStations,
  eventStations,
  temporaryNonShiftStation,
  currentDate,
  forceRefetch,
  staffingListsResponse,
  bulkCalls,
  employeesOff,
  autoHireInfo,
}: Props) => {
  const roster = useRoster({
    selectedBattalionState,
    shiftSummary,
    administrationStations,
    temporaryNonShiftStation,
    eventStations,
    employeesOff,
    currentDate,
    staffingListsResponse,
    forceRefetch,
  });

  const [isSavingLoading, setIsSavingLoading] = useState(false);
  const [openedDrawer, setOpenedDrawer] = useState<DRAWER_TYPE | null>(null);

  const vacantStations = useMemo(() => {
    return getVacantStations(Array.from(roster.shiftSummaryHelper.allStationCards.values()));
  }, [roster.shiftSummaryHelper.allStationCards]);

  const [alertShown, setAlertShown] = useState(false);
  const { state: authUserState } = useLoadedAuthUserContext();
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const refreshTriggerChannel = departmentContext.refreshTriggerChannel;
  const [openMiniCalendar, setOpenMiniCalendar] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);

  const capabilities = useAuthUserCapabilities();

  useEffect(() => {
    if (!refreshTriggerChannel || !forceRefetch) return;
    const handlePusherUpdate: RefreshEventCallback = (data) => {
      if ((data.triggerAll || data.message === PUSHER_UPDATE_MESSAGE) && !alertShown) {
        const currentDateStr = format(currentDate, 'yyyy-MM-dd');
        if (
          isSavingLoading ||
          isAfter(roster.shiftSummaryHelper.createdAt, data.createdAt) ||
          !data.data ||
          data.data.shiftDate !== currentDateStr
        )
          return;
        setSaveDisabled(true); // disable the save button
        SnackbarService.notify({
          severity: 'info',
          content: 'There have been new changes in the schedule. Do you want to refresh the page?',
          actionButtonText: 'Refresh',
          showCloseButton: false,
          onCallToAction: () => {
            forceRefetch();
            SnackbarService.clearQueue();
          },
        });
        setAlertShown(true);
      }
    };

    const EVENT_TYPES_LISTENED = [PUSHER_EVENT_TYPES.STAFFING];

    refreshTriggerChannel.bind_many(EVENT_TYPES_LISTENED, handlePusherUpdate);

    return () => {
      if (refreshTriggerChannel) {
        refreshTriggerChannel.unbind_many(EVENT_TYPES_LISTENED);
      }
      setAlertShown(false);
    };
  }, [refreshTriggerChannel, forceRefetch, alertShown, isSavingLoading, roster, setSaveDisabled, currentDate]);

  const getShiftSummaryAssignmentPayload = () => {
    const payload: ShiftSummaryAssignmentPayload[] = [];

    const pushVacancyPayload = (vacancy: RosterPosition, apparatus: RosterApparatus) => {
      payload.push({
        employeeId: null,
        apparatusId: apparatus.id,
        positionId: vacancy.id,
        startTime: differenceInUTCMinutes(vacancy.startDateTime, roster.shiftSummaryHelper.shiftDuration.startTime),
        endTime: differenceInUTCMinutes(vacancy.endDateTime, roster.shiftSummaryHelper.shiftDuration.startTime),
        payCodes: [],
        detailCodes: [],
        isMandatory: false,
        isVoluntary: false,
        staffedAt: null,
      });
    };

    const pushEmployeePayload = (employee: RosterEmployee, position?: RosterPosition, apparatus?: RosterApparatus) => {
      payload.push({
        employeeId: employee.id,
        apparatusId: apparatus?.id || null,
        positionId: position && !position.isTemporary ? position.id : null,
        startTime: differenceInUTCMinutes(employee.startDateTime, roster.shiftSummaryHelper.shiftDuration.startTime),
        endTime: differenceInUTCMinutes(employee.endDateTime, roster.shiftSummaryHelper.shiftDuration.startTime),
        payCodes: employee.payCodes.map((pc) => pc.id),
        detailCodes: employee.detailCodes.map((dc) => dc.id),
        isMandatory: checkHasMandatoryOvertimeDetailCode(employee),
        isVoluntary: checkHasVoluntaryOvertimeDetailCode(employee),
        staffedAt: employee.staffedAt || null,
      });
    };

    Array.from(roster.shiftSummaryHelper.allStationCards.values()).forEach((station) => {
      if (checkIsShift(station)) {
        station.apparatuses.forEach((apparatus) => {
          apparatus.positions.forEach((position) => {
            getVacancies(position).forEach((vacancy) => pushVacancyPayload(vacancy, apparatus));
            position.employees.forEach((employee) => pushEmployeePayload(employee, position, apparatus));
          });
        });
      }
    });

    roster.shiftSummaryHelper.unassignedCards.forEach((unassigned) => pushEmployeePayload(unassigned));

    return payload;
  };

  const getShiftEmployeeNotesPayload = () => {
    const employees = getBoardEmployees(roster.shiftSummaryHelper).filter((e) => {
      return (
        (checkIsShift(e) || checkIsStrikeTeam(e)) &&
        (!e.noteOverride || e.noteOverride.overrideBy.id === authUserState.employee.id)
      );
    });
    return employees.map((e) => ({ employeeId: e.id, note: e.noteOverride?.note || null }));
  };

  useEffect(() => {
    if (roster.selectedEmptyPositionState.position && checkIsShift(roster.selectedEmptyPositionState.position)) {
      setOpenedDrawer(DRAWER_TYPE.HIREBACK);
    }
  }, [roster.selectedEmptyPositionState.position]);

  const saveChanges = async () => {
    setOpenedDrawer(null);
    let step = '';
    try {
      setIsSavingLoading(true);
      step = 'CANCEL_TEMPORARY_NON_SHIFT_ASSIGNMENTS';
      const unassigns = roster.employeesOffState.temporaryNonShiftIdsToCancel.map((id) => ({
        temporaryNonShiftAssignmentId: id,
      }));
      if (unassigns.length > 0) {
        await client.post('/non-shift/temporary-lists/', {
          date: format(roster.shiftSummaryHelper.shiftDuration.startTime, 'yyyy-MM-dd'),
          unassigns,
        });
      }

      step = 'SAVE_SHIFT_SUMMARY';
      const cancelledShiftTradeIds = roster.cancelShiftTradeState.payloads.map((payload) => payload.shiftTradeId);
      // We need to exclude time off requests for shift trades that we are cancelling
      const filteredSplitShiftOrTimeOffPayloads = roster.splitShiftOrTimeOffState.payloads.filter((payload) => {
        return !payload.employeeTradeId || !cancelledShiftTradeIds.includes(payload.employeeTradeId);
      });
      const response = await client.post(`/shift/summary/?shift_date=${format(currentDate, 'MM/dd/yyyy')}`, {
        battalionId: shiftSummary.battalion.id,
        assignments: getShiftSummaryAssignmentPayload(),
        cancelShiftTrades: roster.cancelShiftTradeState.payloads,
        createShiftTrades: roster.forceShiftTradeState.payloads,
        cancelTimeOffs: roster.employeesOffState.timeOffIdsToCancel.map((id) => ({ timeOffId: id })),
        createTimeOffs: filteredSplitShiftOrTimeOffPayloads,
        employeeNotes: getShiftEmployeeNotesPayload(),
      });
      step = 'SAVE_STRIKE_TEAMS';
      await roster.saveStrikeTeamsState.save();
      step = 'SAVE_TEMPORARY_NON_SHIFT_ASSIGNMENTS';
      await roster.temporaryNonShiftAssignmentsState.save();
      forceRefetch();
      SnackbarService.notify({
        content: response.data.message,
        severity: 'success',
        duration: 5000,
      });
    } catch (error) {
      let message = '';
      if (step === 'SAVE_SHIFT_SUMMARY') {
        message = isAxiosError(error) ? error.response?.data.message : '';
        message = message || 'Error saving changes';
      } else if (step === 'SAVE_STRIKE_TEAMS') {
        message = isAxiosError(error) ? error.response?.data.nonFieldErrors?.[0] : '';
        message = message || 'Error saving events';
      } else if (step === 'SAVE_TEMPORARY_NON_SHIFT_ASSIGNMENTS') {
        message = isAxiosError(error) ? error.response?.data.nonFieldErrors?.[0] : '';
        message = message || `Error saving ${Array.from(TEMPORARY_NON_SHIFT_TITLES.values()).join(', ').toLowerCase()}`;
      } else if (step === 'CANCEL_TEMPORARY_NON_SHIFT_ASSIGNMENTS') {
        message = isAxiosError(error) ? error.response?.data.nonFieldErrors?.[0] : '';
        message = message || `Error cancelling ${Array.from(TEMPORARY_NON_SHIFT_TITLES.values()).join(', ').toLowerCase()}`;
      }
      SnackbarService.notify({ content: message, severity: 'error' });
      captureException(error);
      setIsSavingLoading(false);
    }
  };

  const groupedVacancies = useMemo(() => {
    const today = startOfDay(new Date());
    const currentDateStart = startOfDay(currentDate);

    if (!isAfter(currentDateStart, today)) {
      return {};
    }

    const allVacancies = vacantStations.flatMap((station) =>
      station.apparatuses.flatMap((apparatus) =>
        apparatus.vacancies.map((vacancy) => ({
          ...vacancy,
          stationName: station.stationName,
          apparatusName: apparatus.name,
        })),
      ),
    );

    const sortedVacancies = allVacancies.sort((a, b) => a.rank.sortOrder - b.rank.sortOrder);

    const grouped = sortedVacancies.reduce(
      (groups, vacancy) => {
        const rankId = vacancy.rank.id;
        if (!groups[rankId]) {
          groups[rankId] = [];
        }
        groups[rankId].push(vacancy);
        return groups;
      },
      {} as Record<number, AutoHireVacancy[]>,
    );

    return Object.fromEntries(Object.entries(grouped).filter(([rankId, vacancies]) => vacancies.length > 0));
  }, [vacantStations, currentDate]);

  return (
    <RosterContextProvider roster={roster}>
      <Box>
        <TopBar
          saveChanges={saveChanges}
          isSaving={isSavingLoading}
          shiftTeams={roster.shiftSummaryHelper.currentTeams}
          shiftColor={roster.shiftSummaryHelper.shiftColor}
          openedDrawer={openedDrawer}
          setOpenedDrawer={setOpenedDrawer}
          toggleMiniCalendar={() => setOpenMiniCalendar(!openMiniCalendar)}
          saveDisabled={saveDisabled}
          groupedVacancies={groupedVacancies}
          forceRefetch={forceRefetch}
          autoHireInfo={autoHireInfo}
          vacantStations={vacantStations}
          bulkCalls={bulkCalls}
        />
      </Box>
      {openMiniCalendar && (
        <Box
          sx={(theme) => ({
            position: 'fixed',
            zIndex: theme.zIndex.appBar,
            backgroundColor: 'white',
            top: '57px',
            left: '81px',
            border: '1px solid #DEE3ED',
            borderRadius: '12px',
          })}
        >
          <MiniCalendar />
        </Box>
      )}
      <Box
        sx={(theme) => ({
          background: theme.palette.stationGray[100],
          flex: 1,
          position: 'relative',
          overflow: 'hidden',
          '& .SWRosterBoard-root': {
            mr: openedDrawer ? `${DRAWER_WIDTH[openedDrawer]}px` : 0,
          },
        })}
      >
        <Drawer
          isOpen={openedDrawer === DRAWER_TYPE.HIREBACK}
          onClickOutside={() => setOpenedDrawer(null)}
          side={DRAWER_SIDE.RIGHT}
          drawerSize={`${DRAWER_WIDTH.HIREBACK}px`}
        >
          <Box
            data-cy="overtime-list-drawer"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <DrawerHeader
              title={roster.isStaffingListsEnabled ? 'Staffing list' : 'Hireback list'}
              onClick={() => setOpenedDrawer(null)}
              titleComponent={roster.isStaffingListsEnabled ? <StaffingListHeader /> : <HireBackListHeader />}
            >
              <HireBackList />
            </DrawerHeader>
          </Box>
        </Drawer>
        <Drawer
          isOpen={openedDrawer === DRAWER_TYPE.EMPLOYEES_OFF}
          onClickOutside={() => setOpenedDrawer(null)}
          side={DRAWER_SIDE.RIGHT}
          drawerSize={`${DRAWER_WIDTH.EMPLOYEES_OFF}px`}
        >
          <Box
            data-cy="employees-off-drawer"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflowY: 'scroll',
            }}
          >
            <DrawerHeader
              title="Off roster"
              titleComponent={<Box sx={(theme) => ({ pl: theme.spacing(2), typography: 'bodyMSemibold' })}>Off Roster</Box>}
              onClick={() => setOpenedDrawer(null)}
            >
              <EmployeesOff />
            </DrawerHeader>
          </Box>
        </Drawer>
        <Drawer
          isOpen={openedDrawer === DRAWER_TYPE.MESSAGE}
          onClickOutside={() => setOpenedDrawer(null)}
          side={DRAWER_SIDE.RIGHT}
          drawerSize={`${DRAWER_WIDTH.MESSAGE}px`}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflowY: 'scroll',
            }}
          >
            <DrawerHeader
              title="Message"
              titleComponent={<Box sx={(theme) => ({ pl: theme.spacing(2), typography: 'bodyMSemibold' })}>Message</Box>}
              onClick={() => setOpenedDrawer(null)}
            >
              <MessageToWorkingEmployees setOpenedDrawer={setOpenedDrawer} isOpen={openedDrawer === DRAWER_TYPE.MESSAGE} />
            </DrawerHeader>
          </Box>
        </Drawer>
        <Drawer
          isOpen={openedDrawer === DRAWER_TYPE.STAFFING_STATS}
          onClickOutside={() => setOpenedDrawer(null)}
          side={DRAWER_SIDE.RIGHT}
          drawerSize={`${DRAWER_WIDTH.STAFFING_STATS}px`}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflowY: 'scroll',
            }}
          >
            <DrawerHeader
              title="Staffing stats"
              titleComponent={<Box sx={(theme) => ({ pl: theme.spacing(2), typography: 'bodyMSemibold' })}>Staffing stats</Box>}
              onClick={() => setOpenedDrawer(null)}
            >
              <StaffingStatsDrawer selectedDate={currentDate}></StaffingStatsDrawer>
            </DrawerHeader>
          </Box>
        </Drawer>
        <Drawer
          isOpen={openedDrawer === DRAWER_TYPE.CHANGELOG}
          onClickOutside={() => setOpenedDrawer(null)}
          side={DRAWER_SIDE.RIGHT}
          drawerSize={`${DRAWER_WIDTH.CHANGELOG}px`}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflowY: 'scroll',
            }}
          >
            <DrawerHeader
              title="Change Log"
              titleComponent={<Box sx={(theme) => ({ pl: theme.spacing(2), typography: 'bodyMSemibold' })}>Change Log</Box>}
              onClick={() => setOpenedDrawer(null)}
            >
              <RosterChangeLogDrawer selectedDate={currentDate} />
            </DrawerHeader>
          </Box>
        </Drawer>
        <DaySchedule isLoading={isSavingLoading} />
        <PrintDaySchedulePrint />
        {!roster.isReadonly && capabilities.EDIT_EVENTS && (
          <>
            <CreateButton onClick={() => roster.createStrikeTeamModalState.setIsOpen(true)} title="Create event" />
            <Modal
              open={roster.createStrikeTeamModalState.isOpen}
              setOpen={roster.createStrikeTeamModalState.setIsOpen}
              disableBackdropClick
            >
              <StrikeTeam />
            </Modal>
          </>
        )}
        <MoveToTemporaryNonShiftModal temporaryNonShiftType={TemporaryNonShiftType.LIGHT_DUTY} />
        <MoveToTemporaryNonShiftModal temporaryNonShiftType={TemporaryNonShiftType.EXTENDED_LEAVE} />
        <ForceShiftTradeModal />
        <EditStrikeTeamModal />
        <UnplannedAssignmentWarning />
      </Box>
    </RosterContextProvider>
  );
};

import React from 'react';
import {
  Capability,
  DepartmentInfoLoader,
  RequireAuthProtector,
  RequireCapabilityProtector,
} from '@stationwise/component-module';
import { NoMatch } from '../app/pages/NoMatch';
import { ROUTES } from '../core/Routes';

interface Props {
  capabilities?: Capability[];
  children: React.ReactElement;
}

export const AuthPage = ({ capabilities, children }: Props) => {
  const renderedChildren = capabilities ? (
    <RequireCapabilityProtector capabilities={capabilities} restrictedComponent={<NoMatch />}>
      <DepartmentInfoLoader redirectTo={ROUTES.LOGIN.fullRoute}>{children}</DepartmentInfoLoader>
    </RequireCapabilityProtector>
  ) : (
    <DepartmentInfoLoader redirectTo={ROUTES.LOGIN.fullRoute}>{children}</DepartmentInfoLoader>
  );

  return (
    <div>
      <RequireAuthProtector redirectTo={ROUTES.LOGIN.fullRoute}>{renderedChildren}</RequireAuthProtector>
    </div>
  );
};

import { SxProps, Theme } from '@mui/material';
import { RosterApparatus, RosterPosition } from '@stationwise/share-types';
import { checkIsShift, checkIsStrikeTeam } from '@stationwise/shift-summary-helper';
import { EmptyPosition } from '../../../Position/EmptyPosition';
import { useRosterContext } from '../RosterContext';

interface Props {
  apparatus: RosterApparatus;
  position: RosterPosition;
  isCollapsed: boolean;
  isOver: boolean;
  cardSxProps?: SxProps<Theme>;
}

export const RosterEmptyPositionCard = ({ apparatus, position, isCollapsed, isOver, cardSxProps }: Props) => {
  const { isReadonly, selectedEmptyPositionState } = useRosterContext();
  const hasCandidateAssignee = selectedEmptyPositionState.candidateAssigneeIds.size > 0;
  const isSelectable = !isReadonly && (checkIsShift(position) || checkIsStrikeTeam(position));
  const isNotSelected = hasCandidateAssignee && selectedEmptyPositionState.position?.id !== position.id;

  return (
    <EmptyPosition
      position={position}
      isCollapsed={isCollapsed}
      isOver={isOver}
      onClick={() => selectedEmptyPositionState.setPosition(position)}
      isNotSelected={isNotSelected}
      isSelectable={isSelectable}
      cardSxProps={[{ border: 0 }, ...(Array.isArray(cardSxProps) ? cardSxProps : cardSxProps ? [cardSxProps] : [])]}
    />
  );
};

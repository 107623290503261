import { Box, CircularProgress, InputAdornment, Link as MUILink, Theme, useMediaQuery } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { captureException } from '@sentry/react';
import { FormEvent, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  useDocumentTitle,
  useNextURLQueryParam,
  CircleWarningIcon20,
  EyeIcon20,
  EyeOffIcon20,
  Link03Icon20,
  StationwiseTextLogo,
  Logo,
  Button,
  ErrorBanner,
  Input,
} from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { ROUTES } from '../../../core/Routes';

export const LoginPage = () => {
  useDocumentTitle('Login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const nextUrl = useNextURLQueryParam();
  const isMobileApp = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const setAnError = (message: string) => {
    setErrorMessage(message);
    setEmail('');
    setPassword('');
  };

  function absoluteRedirection(redirectionURL: string) {
    window.location.href = redirectionURL;
  }

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }

    if (email.endsWith('dev.stationwise.com')) {
      absoluteRedirection('https://mobile.dev.stationwise.com');
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await client.post('/auth/login/', {
        email,
        password,
      });
      if (response.status === 206) {
        navigate('/login/reset-password-first-login?email=' + encodeURIComponent(email), {
          state: { oldPassword: btoa(password), firstPasswordChange: !response.data.lastPasswordChange },
        });
      } else {
        if (nextUrl && nextUrl.includes('/n/')) {
          absoluteRedirection(nextUrl);
        } else if (!isMobileApp && response.data.hasAdminPermission) {
          navigate(nextUrl || ROUTES.DASHBOARD.fullRoute);
        } else {
          navigate(nextUrl || ROUTES.MY_DASHBOARD.fullRoute);
        }
      }
    } catch (err) {
      const message = isAxiosError(err) ? err.response?.data.error : '';
      if (!message) {
        captureException(err);
      }
      setAnError(message || 'Something went wrong please try again later');
    }
    setIsSubmitting(false);
  };

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await handleSubmit();
  };

  const EyeToggleIcon20 = isPasswordShown ? EyeOffIcon20 : EyeIcon20;

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.up('sm')]: {
          backgroundColor: theme.palette.stationGray[200],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          overflow: 'auto',
        },
      })}
    >
      <Box
        component="form"
        onSubmit={handleFormSubmit}
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          px: 2,
          py: 4,
          [theme.breakpoints.up('sm')]: {
            m: 'auto',
            px: 12,
            pt: 6,
            pb: 11,
          },
        })}
      >
        <Box sx={{ mx: 'auto', width: 328, maxWidth: '100%' }}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              mb: 5,
              [theme.breakpoints.up('sm')]: {
                mb: 9,
              },
            })}
          >
            <Logo />
            <StationwiseTextLogo />
          </Box>
          {(process.env['NX_PUBLIC_SW_ENV'] === 'develop' || process.env['NX_PUBLIC_SW_ENV'] === 'dev') && (
            <Box textAlign="center" component="h3">
              Dev Env
            </Box>
          )}
          <Box
            component="h1"
            sx={(theme) => ({
              color: theme.palette.stationGray[900],
              mt: 0,
              mb: 4,
              typography: 'heading4',
              textAlign: 'center',
              [theme.breakpoints.up('sm')]: {
                fontSize: '32px',
                mb: 6,
              },
            })}
          >
            Sign in to your workspace
          </Box>
          <Button
            buttonType="tertiary"
            component={RouterLink}
            to={nextUrl ? `/login/magic-link/?next=${nextUrl}` : '/login/magic-link'}
            sx={(theme) => ({
              gap: 1,
              p: '9px 17px',
              width: '100%',
              typography: 'bodyMMedium',
              border: `1px solid ${theme.palette.stationGray[300]}`,
              borderRadius: '6px',
            })}
          >
            <Link03Icon20 />
            <span>Sign in with magic link</span>
          </Button>
          <Button
            buttonType="secondary"
            component={RouterLink}
            to="/apparatus-login"
            sx={(theme) => ({
              display: {
                // only displays large mobile devices if it's a mobile app,
                // we can fetch isTablet from the app context later.
                xs: 'none',
                sm: 'flex',
                md: 'flex',
                lg: 'none',
              },
              mt: 2,
              gap: 1,
              p: '9px 17px',
              width: '100%',
              typography: 'bodyMMedium',
              backgroundColor: theme.palette.stationGray[200],
              borderRadius: '6px',
            })}
          >
            <span>Apparatus login</span>
          </Button>
          <Box
            sx={(theme) => ({
              borderTop: `1px solid ${theme.palette.stationGray[300]}`,
              position: 'relative',
              my: 5,
            })}
          >
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.common.white,
                color: theme.palette.stationGray[400],
                p: '0 10px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                typography: 'bodySRegular',
                lineHeight: 1,
                whiteSpace: 'nowrap',
              })}
            >
              Or use your email instead
            </Box>
          </Box>
          {!isSubmitting && !!errorMessage && <ErrorBanner sx={{ mt: -1, mb: 2 }}>{errorMessage}</ErrorBanner>}
          <Box sx={{ mb: 3 }}>
            <Input
              data-cy="email"
              disabled={isSubmitting}
              error={!!errorMessage}
              label="Email"
              autoComplete="email"
              placeholder="you@example.gov"
              type="text"
              value={email}
              onChange={(event) => {
                let value = event.currentTarget.value;
                if (value.includes(' ')) {
                  value = value.replace(/\s/g, '');
                }
                setEmail(value);
                setErrorMessage('');
              }}
              endAdornment={
                !errorMessage ? undefined : (
                  <InputAdornment position="end">
                    <CircleWarningIcon20 />
                  </InputAdornment>
                )
              }
            />
          </Box>
          <Box sx={{ mb: 5 }}>
            <Input
              data-cy="password"
              disabled={isSubmitting}
              error={!!errorMessage}
              label="Password"
              type={isPasswordShown ? 'text' : 'password'}
              value={password}
              onChange={(event) => {
                setPassword(event.currentTarget.value);
                setErrorMessage('');
              }}
              endAdornment={
                <InputAdornment position="end">
                  {errorMessage ? (
                    <CircleWarningIcon20 />
                  ) : (
                    <Box
                      component="button"
                      type="button"
                      aria-label={isPasswordShown ? 'Hide password' : 'Show password'}
                      onClick={() => setIsPasswordShown(!isPasswordShown)}
                      sx={(theme) => ({
                        backgroundColor: 'transparent',
                        border: 0,
                        color: theme.palette.stationGray[400],
                        cursor: 'pointer',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        WebkitTapHighlightColor: 'transparent',
                        mr: -1,
                        p: 1,
                      })}
                    >
                      <EyeToggleIcon20 />
                    </Box>
                  )}
                </InputAdornment>
              }
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
              <MUILink
                component={RouterLink}
                to={nextUrl ? `/login/forgot-password/?next=${nextUrl}` : '/login/forgot-password'}
                sx={(theme) => ({ typography: 'bodySMedium', textDecoration: 'none' })}
              >
                Forgot Password?
              </MUILink>
            </Box>
          </Box>
          <Button
            data-cy="submit-login-form"
            buttonType="primary"
            type="submit"
            disabled={!(email && password)}
            sx={() => ({
              p: '9px 17px',
              width: '100%',
              typography: 'bodyMMedium',
            })}
          >
            {isSubmitting && <CircularProgress color="inherit" size="1.5rem" />}
            <Box component="span" sx={isSubmitting ? visuallyHidden : {}}>
              Sign in
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

import { format, max } from 'date-fns';
import { RosterDuration, RosterShiftDuration } from '@stationwise/share-types';

export const formatShiftTime = (time: Date) => format(time, 'HH:mm');

export const formatShiftDuration = ({ startTime, endTime }: Pick<RosterShiftDuration, 'startTime' | 'endTime'>) => {
  return `${formatShiftTime(startTime)} - ${formatShiftTime(endTime)}`;
};

const getHourMinutes = (time: Date) => time.getHours() * 60 + time.getMinutes();

export const diffCycleMinutes = (endTime: Date | null, startTime: Date | null) => {
  if (!endTime || !startTime) {
    return 0;
  }
  // E.g. diffCycleMinutes(23:00, 07:00) = 16 * 60.
  // E.g. diffCycleMinutes(05:00, 07:00) = 22 * 60. 05:00 means 05:00 the next day.
  const minutes = getHourMinutes(endTime) - getHourMinutes(startTime);
  return (minutes + 24 * 60) % (24 * 60);
};

export const offsetMinutes = (relatedTime: Date | null, anchorTime: Date | null) => {
  if (!relatedTime || !anchorTime) {
    return 0;
  }
  // E.g. offsetMinutes(23:00, 07:00) = 16 * 60.
  // E.g. offsetMinutes(05:00, 07:00) = -2 * 60.
  const minutes = getHourMinutes(relatedTime) - getHourMinutes(anchorTime);
  return minutes % (24 * 60);
};

export const mergeDurations = (durations: RosterDuration[]) => {
  const merged: RosterDuration[] = [];
  durations.forEach((duration) => {
    const prevDuration = merged[merged.length - 1];
    if (prevDuration && prevDuration.endDateTime >= duration.startDateTime) {
      prevDuration.endDateTime = max([prevDuration.endDateTime, duration.endDateTime]);
    } else {
      merged.push({ ...duration });
    }
  });
  return merged;
};

export const cutDuration = (duration: RosterDuration, inputCuts: RosterDuration[]) => {
  const cuts = mergeDurations(inputCuts);
  const remains: RosterDuration[] = [];
  if (!cuts.length) {
    remains.push({ startDateTime: duration.startDateTime, endDateTime: duration.endDateTime });
    return remains;
  }

  remains.push({ startDateTime: duration.startDateTime, endDateTime: cuts[0].startDateTime });
  for (let i = 1; i < cuts.length; i++) {
    remains.push({ startDateTime: cuts[i - 1].endDateTime, endDateTime: cuts[i].startDateTime });
  }
  remains.push({ startDateTime: cuts[cuts.length - 1].endDateTime, endDateTime: duration.endDateTime });
  return remains.filter((d) => d.endDateTime > d.startDateTime);
};

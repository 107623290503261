import { RosterDataSource } from '@stationwise/share-types';
import { COLLISION_REGION } from '../constants';
import { setEmployeeActiveId } from '../id';
import { IShiftSummaryHelperCollisionParams } from '../types';
import { sortUnassignedCards } from '../unassigned';

export const addActiveToUnassigned = (params: IShiftSummaryHelperCollisionParams) => {
  const { over, active } = params;
  if (over.region !== COLLISION_REGION.FLOATERS_BAR) {
    throw new Error('Cannot add active to unassigned');
  }

  const newEmployee = setEmployeeActiveId({ ...active.employee, dataSource: RosterDataSource.FLOATER });

  const newUnassignedCards = sortUnassignedCards([...params.unassignedCards, newEmployee]);

  const newActive = { employee: newEmployee };
  return { ...params, newActive, unassignedCards: newUnassignedCards };
};

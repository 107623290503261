import { Box } from '@mui/material';
import { useState } from 'react';
import { makeOvertimeEmployeeActiveId } from '@stationwise/shift-summary-helper';
import { useAuthUserCapabilities } from '../../../../utils/capabilities';
import { ErrorBanner } from '../../../ErrorBanner';
import { Loader } from '../../../Loader';
import { SearchInput } from '../../../SearchInput';
import { DraggableCard } from '../DraggableCard';
import { useRosterContext } from '../RosterContext';
import { StaffingListControls, StaffingListCards } from '../StaffingList';
import { HireBackOperatorCard } from './HireBackOperatorCard';
import { MultipleFilter } from './MultipleFilter';
import { SortFilter } from './SortFilter';
import {
  getNonRepeatedCertifications,
  getNonRepeatedRanks,
  filterHireBackList,
  sortHireBackList,
  OVERTIME_SORT_OPTIONS,
} from './helpers';

export const HireBackList = () => {
  const { isStaffingListsEnabled, staffingListsState } = useRosterContext();
  const operators = staffingListsState.availableStaffingListItems;
  const sortOptions = operators.length ? OVERTIME_SORT_OPTIONS : [];
  const multipleFilterOptions = [...getNonRepeatedCertifications({ operators }), ...getNonRepeatedRanks({ operators })];

  const capabilities = useAuthUserCapabilities();

  const [searchInput, setSearchInput] = useState('');
  const [selectedRankOptions, setSelectedRankOptions] = useState(new Set<string>());
  const [selectedCertOptions, setSelectedCertOptions] = useState(new Set<string>());
  const [selectedTeamOptions, setSelectedTeamOptions] = useState(new Set<string>());
  const [sortValue, setSortValue] = useState<string>(OVERTIME_SORT_OPTIONS[0]);
  const [multipleFilterSelectedOptions, setMultipleFilterSelectedOptions] = useState<string[]>([]);

  return (
    <Box data-cy="hireback-list" display="flex" flexDirection="column" width="100%" height="100%">
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', pt: 2.5 }}>
        {isStaffingListsEnabled && (
          <StaffingListControls
            items={operators}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            selectedRankOptions={selectedRankOptions}
            setSelectedRankOptions={setSelectedRankOptions}
            selectedCertOptions={selectedCertOptions}
            setSelectedCertOptions={setSelectedCertOptions}
            selectedTeamOptions={selectedTeamOptions}
            setSelectedTeamOptions={setSelectedTeamOptions}
            sortValue={sortValue}
            setSortValue={setSortValue}
          />
        )}
        {!isStaffingListsEnabled && (
          <Box sx={{ px: 2 }}>
            <SearchInput
              setValue={setSearchInput}
              value={searchInput}
              placeHolder="Search name, rank, or certification"
              color="lightGray"
            />
            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
              <Box sx={{ flex: 1 }}>
                <SortFilter options={sortOptions} selectedOptions={sortValue} setSelectedOptions={setSortValue} />
              </Box>
              <Box sx={{ width: 236 }}>
                <MultipleFilter
                  options={multipleFilterOptions}
                  setSelectedOptions={setMultipleFilterSelectedOptions}
                  selectedOptions={multipleFilterSelectedOptions}
                />
              </Box>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: isStaffingListsEnabled ? 1 : 1.5,
            overflowY: 'auto',
            position: 'relative',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { width: 0 },
            mt: isStaffingListsEnabled ? 0 : 3,
            px: 2,
            flexGrow: 1,
          }}
        >
          {!staffingListsState.isError && !staffingListsState.selectedStaffingList?.items && <Loader />}
          {staffingListsState.isError && !staffingListsState.selectedStaffingList?.items && (
            <ErrorBanner>
              {isStaffingListsEnabled ? 'Unable to load staffing list.' : 'Unable to load overtime list.'}
            </ErrorBanner>
          )}
          {isStaffingListsEnabled && (
            <StaffingListCards
              items={operators}
              searchInput={searchInput}
              selectedRankOptions={selectedRankOptions}
              selectedCertOptions={selectedCertOptions}
              selectedTeamOptions={selectedTeamOptions}
              sortValue={sortValue}
            />
          )}
          {!isStaffingListsEnabled &&
            filterHireBackList({ operators, filterConditions: multipleFilterSelectedOptions, searchInput })
              .sort(sortHireBackList(sortValue))
              .map((operator) => (
                <DraggableCard
                  activeId={makeOvertimeEmployeeActiveId(operator.employee.id)}
                  disabled={!capabilities.MANAGE_ROSTER_HIRING}
                  key={operator.employee.id}
                  sx={{ height: 'auto' }}
                >
                  <HireBackOperatorCard operator={operator} />
                </DraggableCard>
              ))}
        </Box>
      </Box>
    </Box>
  );
};

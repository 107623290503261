import { RosterApparatus, RosterPosition, RosterEmployee } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { cutDuration } from '../datetime';
import { IShiftSummaryHelper } from '../types';

export const getBoardPosition = ({ allStationCards }: IShiftSummaryHelper, positionId: string) => {
  for (const station of allStationCards.values()) {
    for (const apparatus of station.apparatuses) {
      const positionIndex = apparatus.positions.findIndex((position) => position.id === positionId);
      const position = apparatus.positions[positionIndex];
      if (position) {
        return { station, apparatus, position, positionIndex };
      }
    }
  }
  return { station: null, apparatus: null, position: null, positionIndex: -1 };
};

export const makeTemporaryPosition = (apparatus: RosterApparatus, employee: RosterEmployee): RosterPosition => {
  return {
    id: `${apparatus.id}|${employee.activeId}`,
    dataSource: apparatus.dataSource,
    employees: [],
    startDateTime: employee.startDateTime,
    endDateTime: employee.endDateTime,
    rank: employee.rank,
    certifications: [],
    isTemporary: true,
    driver: false,
  };
};

export const sortTemporaryPositions = (positions: RosterPosition[]) => {
  const regularPositions = positions.filter((p) => !p.isTemporary);
  const temporaryPositions = positions.filter((p) => p.isTemporary);
  temporaryPositions.sort((a, b) => {
    const aId = Number(a.employees[0]?.id || 0);
    const bId = Number(b.employees[0]?.id || 0);
    return aId - bId || differenceInUTCMinutes(a.startDateTime, b.startDateTime);
  });
  return [...regularPositions, ...temporaryPositions];
};

export const getPositionEmployeeSortKey = (a: RosterEmployee, b: RosterEmployee) => {
  return (
    differenceInUTCMinutes(a.startDateTime, b.startDateTime) ||
    differenceInUTCMinutes(a.endDateTime, b.endDateTime) ||
    Number(a.id) - Number(b.id)
  );
};

const makeVacancy = (position: RosterPosition, duration: Pick<RosterPosition, 'startDateTime' | 'endDateTime'>) => {
  return { ...position, employees: [], startDateTime: duration.startDateTime, endDateTime: duration.endDateTime };
};

export const getVacancies = (position: RosterPosition) => {
  if (position.isTemporary) {
    return [];
  }

  const employees = position.employees.slice().sort(getPositionEmployeeSortKey);
  return cutDuration(position, employees).map((d) => makeVacancy(position, d));
};

export const getPositionSplits = (position: RosterPosition) => {
  const splits = [
    ...position.employees.map((employee) => ({ vacancy: makeVacancy(position, employee), employee })),
    ...getVacancies(position).map((vacancy) => ({ vacancy, employee: null })),
  ];
  return splits.sort((a, b) => {
    // Two splits have the same start and end time only when both have employees,
    // so it's not important what the code does in the `employee: null` case.
    return (
      differenceInUTCMinutes(a.vacancy.startDateTime, b.vacancy.startDateTime) ||
      differenceInUTCMinutes(a.vacancy.endDateTime, b.vacancy.endDateTime) ||
      Number(a.employee?.id || 0) - Number(b.employee?.id || 0)
    );
  });
};

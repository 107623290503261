import { SxProps, Theme, useTheme } from '@mui/material';
import { RosterApparatus, RosterPosition, RosterEmployee } from '@stationwise/share-types';
import { checkIsActingAsRank, checkIsPlannedEmployee, checkIsShift } from '@stationwise/shift-summary-helper';
import { FilledPosition } from '../../../Position/FilledPosition';
import { getEmployeeCardStyles } from '../EmployeeCard';
import { useRosterContext } from '../RosterContext';

interface Props {
  apparatus: RosterApparatus;
  position: RosterPosition;
  employee: RosterEmployee;
  isCollapsed: boolean;
  cardSxProps?: SxProps<Theme>;
}

export const RosterAssignedEmployeeCard = ({ apparatus, position, employee, isCollapsed, cardSxProps, ...props }: Props) => {
  const theme = useTheme();
  const { selectedEmptyPositionState, shiftSummaryHelper } = useRosterContext();
  const isPlannedEmployee = checkIsPlannedEmployee(shiftSummaryHelper, employee);
  const cardType = !isPlannedEmployee && checkIsShift(position) ? 'border' : 'default';

  return (
    <FilledPosition
      actingAs={checkIsActingAsRank(shiftSummaryHelper, position, employee)}
      isCollapsed={isCollapsed}
      position={position}
      employee={employee}
      cardType={cardType}
      {...props}
      cardSxProps={[
        { border: 0 },
        getEmployeeCardStyles({ theme, employeeActiveId: employee.activeId, selectedEmptyPositionState }),
        ...(Array.isArray(cardSxProps) ? cardSxProps : cardSxProps ? [cardSxProps] : []),
      ]}
    />
  );
};

import { copyBoardWithNewApparatus, getPositionEmployeeSortKey, makeTemporaryPosition, sortTemporaryPositions } from '../board';
import { COLLISION_REGION } from '../constants';
import { getMandatoryDetailCodes } from '../detailCode';
import { setEmployeeActiveId } from '../id';
import { getMandatoryPayCodes } from '../payCode';
import { IShiftSummaryHelperCollisionParams } from '../types';

export const addActiveToApparatus = (params: IShiftSummaryHelperCollisionParams) => {
  // Add employee to excess capacity by appending a temporary position to the apparatus.
  const { over, active } = params;
  if (over.region !== COLLISION_REGION.APPARATUS || over.position) {
    throw new Error('Cannot add active to apparatus');
  }

  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(params, over.apparatus.id);
  const newEmployee = setEmployeeActiveId({ ...active.employee, dataSource: newApparatus.dataSource });
  const newPosition = makeTemporaryPosition(newApparatus, newEmployee);
  newEmployee.payCodes = getMandatoryPayCodes(params, newPosition, newEmployee);
  newEmployee.detailCodes = getMandatoryDetailCodes(params, newPosition, newEmployee);
  newPosition.employees.push(newEmployee);
  newApparatus.positions = sortTemporaryPositions([...newApparatus.positions, newPosition]);
  const newActive = { employee: newEmployee };
  return { ...params, newActive, allStationCards: newAllStationCards };
};

export const addActiveToPosition = (params: IShiftSummaryHelperCollisionParams) => {
  const { over, active } = params;
  if (over.region !== COLLISION_REGION.APPARATUS || !over.position) {
    throw new Error('Cannot add active to position');
  }

  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(params, over.apparatus.id);
  const newEmployee = setEmployeeActiveId({ ...active.employee, dataSource: newApparatus.dataSource });

  const newPosition = newApparatus.positions.find((p) => p.id === over.position?.id);
  if (!newPosition) {
    throw new Error('Cannot add active to position');
  }

  newEmployee.payCodes = getMandatoryPayCodes(params, newPosition, newEmployee);
  newEmployee.detailCodes = getMandatoryDetailCodes(params, newPosition, newEmployee);
  newPosition.employees.push(newEmployee);
  newPosition.employees.sort(getPositionEmployeeSortKey);
  const newActive = { employee: newEmployee };
  return { ...params, newActive, allStationCards: newAllStationCards };
};

import { RosterDataSource, RosterEmployee, RosterSplitShiftOrTimeOffChange } from '@stationwise/share-types';
import {
  copyBoardWithNewApparatus,
  getBoardEmployee,
  getPositionEmployeeSortKey,
  makeTemporaryPosition,
  sortTemporaryPositions,
} from './board';
import { setEmployeeActiveId } from './id';
import { IShiftSummaryHelper } from './types';

export const splitEmployee = (
  shiftSummaryHelper: IShiftSummaryHelper,
  employeeActiveId: string,
  splits: RosterSplitShiftOrTimeOffChange[],
) => {
  const { apparatus, position, positionIndex, employee, employeeIndex } = getBoardEmployee(shiftSummaryHelper, employeeActiveId);
  if (!employee) {
    throw new Error('Cannot split employee');
  }

  const isFloater = !apparatus || !position;

  const newEmployees: RosterEmployee[] = [];
  splits.forEach((split) => {
    if (split.isEmpty) {
      return;
    }

    const newEmployee = setEmployeeActiveId({
      ...employee,
      dataSource: isFloater ? RosterDataSource.FLOATER : RosterDataSource.STATION,
      startDateTime: split.startTime,
      endDateTime: split.endTime,
    });
    newEmployees.push(newEmployee);
  });

  if (isFloater) {
    const newUnassignedCards = [...shiftSummaryHelper.unassignedCards];
    newUnassignedCards.splice(employeeIndex, 1, ...newEmployees);
    return { ...shiftSummaryHelper, unassignedCards: newUnassignedCards };
  }

  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(shiftSummaryHelper, apparatus.id);
  if (position.isTemporary) {
    const newTemporaryPositions = newEmployees.map((e) => ({ ...makeTemporaryPosition(newApparatus, e), employees: [e] }));
    newApparatus.positions.splice(positionIndex, 1, ...newTemporaryPositions);
    newApparatus.positions = sortTemporaryPositions(newApparatus.positions);
    return { ...shiftSummaryHelper, allStationCards: newAllStationCards };
  }

  newApparatus.positions[positionIndex].employees.splice(employeeIndex, 1, ...newEmployees);
  newApparatus.positions[positionIndex].employees.sort(getPositionEmployeeSortKey);
  return { ...shiftSummaryHelper, allStationCards: newAllStationCards };
};

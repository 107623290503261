import { Address, TemporaryNonShiftAssignment } from '../views';
import { NonShiftEmployee } from '../views/employee/getNonShiftEmployee';
import { EmployeeMaybeConsecutiveWorkDuration } from '../views/employee/models';
import { StrikeTeam } from '../views/event/models';
import {
  ShiftSummaryStation,
  ShiftSummaryApparatus,
  ShiftSummaryPosition,
  ShiftSummaryEmployee,
} from '../views/shift/getShiftSummary';
import { TemporaryNonShiftType } from '../views/temporaryNonShift/models';

export enum RosterDataSource {
  STATION = 'STATION',
  FLOATER = 'FLOATER',
  OVERTIME = 'OVERTIME',
  ADMINISTRATION = 'ADMINISTRATION',
  EVENT = 'EVENT',
  STRIKE_TEAM = 'STRIKE_TEAM',
  TEMPORARY_NON_SHIFT = 'TEMPORARY_NON_SHIFT',
}

export const ROSTER_SHIFT_DATA_SOURCES = new Set([RosterDataSource.STATION, RosterDataSource.FLOATER, RosterDataSource.OVERTIME]);

export const ROSTER_EVENT_DATA_SOURCES = new Set([RosterDataSource.EVENT, RosterDataSource.STRIKE_TEAM]);

export interface RosterDuration {
  startDateTime: Date;
  endDateTime: Date;
}

export interface RosterShiftDuration {
  startTime: Date;
  endTime: Date;
  hours: number;
}

export interface RosterEmployeeDuration {
  source: string;
  startDateTime: Date;
  endDateTime: Date;
}

export interface RosterSplitShiftOrTimeOffChange {
  startTime: Date;
  endTime: Date;
  isEmpty: boolean;
}

export interface RosterEmployee extends Omit<ShiftSummaryEmployee, 'id' | 'startDateTime' | 'endDateTime'> {
  id: string;
  activeId: string;
  dataSource: RosterDataSource;
  startDateTime: Date;
  endDateTime: Date;
  breakDuration?: number;
  maybeConsecutiveWorkDurations?: EmployeeMaybeConsecutiveWorkDuration[];
  staffedAt?: Date;
}

export interface RosterPosition extends Omit<ShiftSummaryPosition, 'id' | 'employees'> {
  id: string;
  dataSource: RosterDataSource;
  startDateTime: Date;
  endDateTime: Date;
  employees: RosterEmployee[];
  isNewlyCreated?: boolean;
}

export interface RosterApparatus extends Omit<ShiftSummaryApparatus, 'id' | 'positions'> {
  id: string;
  dataSource: RosterDataSource;
  positions: RosterPosition[];
  isNewlyCreated?: boolean;
}

export interface RosterStation extends Omit<ShiftSummaryStation, 'stationId' | 'apparatuses'> {
  stationId: string;
  dataSource: RosterDataSource;
  apparatuses: RosterApparatus[];
  address?: Address;
  isNewlyCreated?: boolean;
}

export interface RosterAdministrationPosition extends RosterPosition {
  nonShiftEmployee: NonShiftEmployee;
}

export interface RosterAdministrationApparatus extends Omit<RosterApparatus, 'positions'> {
  positions: RosterAdministrationPosition[];
}

export interface RosterAdministrationStation extends Omit<RosterStation, 'apparatuses'> {
  apparatuses: RosterAdministrationApparatus[];
}

interface StrikeTeamAssignmentPayload {
  positionId: string | null;
  candidateId: number;
  strikeTeamId: number;
  startTime: number;
  endTime: number;
  toDate: string;
  payCodeIds: number[];
  dutyDayPayCodeIds: number[] | null;
  detailCodeIds: number[];
}

interface StrikeTeamUnassignmentPayload {
  positionId: string | null;
  assigneeId: string;
  startTime: number;
  endTime: number;
  toDate: string;
  strikeTeamId: number;
}

export interface RosterTemporaryNonShiftPosition extends RosterPosition {
  temporaryNonShiftAssignment: TemporaryNonShiftAssignment;
}

export interface RosterTemporaryNonShiftApparatus extends RosterApparatus {
  positions: RosterTemporaryNonShiftPosition[];
}

export interface RosterTemporaryNonShiftStation extends Omit<RosterStation, 'apparatuses'> {
  apparatuses: RosterTemporaryNonShiftApparatus[];
}

export interface RosterStrikeTeamApparatus extends RosterApparatus {
  deactivationDate: string;
  strikeTeam: StrikeTeam;
  strikeTeamAssignmentPayloadMap: Map<string, StrikeTeamAssignmentPayload[]>;
  strikeTeamUnassignmentPayloadMap: Map<string, StrikeTeamUnassignmentPayload[]>;
}

export interface RosterTemporaryNonShiftAssignment {
  temporaryNonShiftType: TemporaryNonShiftType;
  candidateId: string;
  toDate: string;
  activeWeekdays: number[] | undefined;
  startTime: number;
  endTime: number;
  payCodeId: number;
  activationDate: Date;
  deactivationDate: Date;
}

export interface RosterCancelShiftTradePayload {
  shiftTradeId: number;
  tradedHoursBalancePolicy: string;
}

export interface RosterCreateShiftTradePayload {
  senderId: string;
  receiverId: string;
  positionId: string | null;
  startTime: number;
  endTime: number;
}

export interface RosterCreateTimeOffPayload {
  employeeId: string;
  positionId: string | null;
  payCode: string;
  startTime: number;
  endTime: number;
  employeeTradeId?: number | null;
}

export interface RosterEmployeeOffPayloads {
  cancelTemporaryNonShiftAssignmentPayloads: number[];
  createTemporaryNonShiftAssignmentPayloads: RosterTemporaryNonShiftAssignment[];
  cancelShiftTradePayloads: RosterCancelShiftTradePayload[];
  createShiftTradePayloads: RosterCreateShiftTradePayload[];
  cancelTimeOffPayloads: number[];
  createTimeOffPayloads: RosterCreateTimeOffPayload[];
}

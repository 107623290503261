import { format } from 'date-fns';
import { client } from '@stationwise/share-api';
import { DEFAULT_RANK, RosterPosition, RosterStrikeTeamApparatus, ShiftSummary } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { IShiftSummaryHelper, checkIsStrikeTeamApparatus } from '@stationwise/shift-summary-helper';

export const useSaveStrikeTeams = (
  shiftSummary: ShiftSummary,
  initialShiftSummaryHelper: IShiftSummaryHelper,
  shiftSummaryHelper: IShiftSummaryHelper,
) => {
  const makeAssignPayload = (apparatus: RosterStrikeTeamApparatus, position: RosterPosition) => {
    return apparatus.strikeTeamAssignmentPayloadMap.get(position.id) || [];
  };

  const makeUnassignPayload = (apparatus: RosterStrikeTeamApparatus, position: RosterPosition) => {
    return apparatus.strikeTeamUnassignmentPayloadMap.get(position.id) || [];
  };

  const makeCreatePayload = (apparatus: RosterStrikeTeamApparatus) => {
    return {
      name: apparatus.name,
      station: apparatus.strikeTeam.station,
      deactivationDate: apparatus.strikeTeam.deactivationDate,
      activeWeekdays: apparatus.strikeTeam.activeWeekdays,
      certificationRequirements: apparatus.certificationRequirements.map((req) => ({
        certificationId: req.id,
        amount: req.amount,
      })),
      positions: apparatus.positions.map((pos) => ({
        actingRankId: pos.rank.id !== DEFAULT_RANK.id ? pos.rank.id : null,
        certificationRequirements: pos.certifications.map((cert) => ({ certificationId: cert.id })),
        startTime: differenceInUTCMinutes(pos.startDateTime, shiftSummaryHelper.shiftDuration.startTime),
        endTime: differenceInUTCMinutes(pos.endDateTime, shiftSummaryHelper.shiftDuration.startTime),
        assignments: makeAssignPayload(apparatus, pos),
        isTemporary: pos.isTemporary,
      })),
    };
  };

  const makeUpdatePayload = (initialApparatus: RosterStrikeTeamApparatus, apparatus: RosterStrikeTeamApparatus) => {
    if (initialApparatus.name === apparatus.name && initialApparatus.strikeTeam.station === apparatus.strikeTeam.station) {
      return null;
    }

    return {
      id: apparatus.strikeTeam.id,
      name: apparatus.name,
      station: apparatus.strikeTeam.station,
    };
  };

  const makeUndeployPayload = (strikeTeamId: number) => ({ strikeTeamId });

  const makeAssignmentUpdatePayload = (apparatus: RosterStrikeTeamApparatus, position: RosterPosition) => {
    return position.employees.map((candidate) => {
      return {
        employeeId: candidate.id,
        positionId: position.isTemporary ? null : position.id,
        strikeTeamId: apparatus.strikeTeam.id,
        startTime: differenceInUTCMinutes(candidate.startDateTime, shiftSummaryHelper.shiftDuration.startTime),
        endTime: differenceInUTCMinutes(candidate.endDateTime, shiftSummaryHelper.shiftDuration.startTime),
        payCodes: candidate.payCodes.map((pc) => pc.id),
        detailCodes: candidate.detailCodes.map((dc) => dc.id),
      };
    });
  };

  const save = async () => {
    const date = format(shiftSummaryHelper.shiftDuration.startTime, 'yyyy-MM-dd');
    const creates: ReturnType<typeof makeCreatePayload>[] = [];
    const assigns: ReturnType<typeof makeAssignPayload> = [];
    const unassigns: ReturnType<typeof makeUnassignPayload> = [];
    const undeploys: ReturnType<typeof makeUndeployPayload>[] = [];
    const updates: ReturnType<typeof makeUpdatePayload>[] = [];
    const assignmentUpdates: ReturnType<typeof makeAssignmentUpdatePayload> = [];
    const currentApparatusMap = new Map<string, RosterStrikeTeamApparatus>();
    shiftSummaryHelper.allStationCards.forEach((station) => {
      station.apparatuses.forEach((apparatus, apparatusIndex) => {
        if (checkIsStrikeTeamApparatus(apparatus)) {
          if (apparatus.strikeTeam.id < 0) {
            creates.push(makeCreatePayload(apparatus));
            return;
          }

          currentApparatusMap.set(apparatus.id, apparatus);
          apparatus.positions.forEach((position, positionIndex) => {
            assigns.push(...makeAssignPayload(apparatus, position));
            unassigns.push(...makeUnassignPayload(apparatus, position));
            assignmentUpdates.push(...makeAssignmentUpdatePayload(apparatus, position));
          });
        }
      });
    });
    initialShiftSummaryHelper.allStationCards.forEach((initialStation) => {
      initialStation.apparatuses.forEach((initialApparatus) => {
        if (checkIsStrikeTeamApparatus(initialApparatus)) {
          const currentApparatus = currentApparatusMap.get(initialApparatus.id);
          if (!currentApparatus) {
            undeploys.push(makeUndeployPayload(initialApparatus.strikeTeam.id));
            return;
          }

          const updatePayload = makeUpdatePayload(initialApparatus, currentApparatus);
          updatePayload && updates.push(updatePayload);

          const currentPositionIds = new Set(currentApparatus.positions.map((p) => p.id));
          initialApparatus.positions.forEach((initialPosition) => {
            if (!currentPositionIds.has(initialPosition.id)) {
              unassigns.push(...makeUnassignPayload(currentApparatus, initialPosition));
            }
          });
        }
      });
    });
    if (!creates.length && !assigns.length && !unassigns.length && !undeploys.length && !updates.length) {
      return;
    }
    await client.post('/event/strike-teams/', {
      date,
      shiftSummaryShiftDateId: shiftSummary.id,
      shiftSummaryBattalionId: shiftSummary.battalion.id,
      creates,
      assigns,
      unassigns,
      undeploys,
      updates,
      assignmentUpdates,
    });
  };

  return { save };
};

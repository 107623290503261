import { Badge, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Logo, useLoadedAuthUserContext } from '@stationwise/component-module';
import { INavMenu } from '../../../app/AppPageLayout';
import { ROUTES } from '../../../core/Routes';
import { MenuIcon } from './MenuIcon';
import { MoreMenu } from './MoreMenu';
import { ProfileMenu } from './ProfileMenu';

interface Props {
  moreNavItems: INavMenu[];
  navItems: INavMenu[];
}

export const SideBar = ({ moreNavItems, navItems }: Props) => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const location = useLocation();
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(-1);

  const isAdmin = ['SUPER_ADMIN', 'ADMIN'].includes(authUserState.employee.role);

  useEffect(() => {
    let isAnyMatching = false;
    navItems.forEach((navItem, index) => {
      const match = navItem.match.some((path) => {
        return matchPath(path, location.pathname);
      });
      if (match) {
        isAnyMatching = true;
        setSelectedMenuIndex(index);
      }
    });
    if (!isAnyMatching) {
      setSelectedMenuIndex(-1);
    }
  }, [location.pathname, navItems]);

  const changeLogIsActive = matchPath(ROUTES.CHANGELOG.fullRoute, location.pathname);
  const inboxIsActive = matchPath(ROUTES.INBOX.fullRoute, location.pathname);
  const notificationCount = authUserState.employee.unreadMessages.length;

  return (
    <Box
      sx={(theme) => ({
        display: { xs: 'none', lg: 'flex' },
        flexDirection: 'column',
        width: '64px',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.palette.common.white,
        p: '12px 0',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: theme.zIndex.drawer,
      })}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
        <Link to={isAdmin ? ROUTES.DASHBOARD.fullRoute : ROUTES.MY_DASHBOARD.fullRoute}>
          <Logo />
        </Link>
        {navItems.map((navItem, index) => (
          <MenuIcon
            key={`${navItem.type}-${navItem.path}`}
            type={navItem.type}
            component={Link}
            to={navItem.path}
            isActive={index === selectedMenuIndex}
          />
        ))}
        {isAdmin && <MoreMenu moreNavItems={moreNavItems} />}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
        <Badge badgeContent={notificationCount} color="primary">
          <MenuIcon type="inbox" isActive={!!inboxIsActive} component={Link} to={ROUTES.INBOX.fullRoute} />
        </Badge>
        {isAdmin && (
          <MenuIcon type="change-log" isActive={!!changeLogIsActive} component={Link} to={ROUTES.CHANGELOG.fullRoute} />
        )}
        <ProfileMenu />
      </Box>
    </Box>
  );
};
